'use client';

import { api } from '@streetferret/api-client/client';
import { Layer, Source } from 'react-map-gl';
import { LivePositionMarker } from './live-position-marker';

interface JourneyLiveLayersProps {
  journeyId: bigint;
  user: {
    firstname: string | null;
    lastname: string | null;
    profile: string | null;
  };
}
/**
 *
 * React-Map-GL component to display a journey user's live position.
 */
export function JourneyLiveLayers({ journeyId, user }: JourneyLiveLayersProps) {
  const latestPositionQuery = api.journey.tracking.livePosition.useQuery(
    {
      journeyId: BigInt(journeyId),
    },
    {
      refetchInterval: 30_000,
    },
  );
  const latestPosition = latestPositionQuery.data;

  const liveProgressQuery = api.journey.tracking.liveProgress.useQuery({
    journeyId: BigInt(journeyId),
  });

  return (
    <>
      {liveProgressQuery.data && (
        <Source
          id="live-progress"
          type="geojson"
          data={liveProgressQuery.data.geojson}>
          <Layer
            id="live-progress"
            type="line"
            paint={{
              'line-width': 2,
              'line-color': `hsl(200, 100%, 50%)`,
            }}
          />
        </Source>
      )}

      <LivePositionMarker position={latestPosition} user={user} />
    </>
  );
}

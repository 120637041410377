'use client';

import { BaseMap, JourneyLayers, UserTilesSource } from '@streetferret/map-web';
import { JourneyLiveLayers } from '@streetferret/ui-rsc/src/journeys/journey-live-layers';
import { useTheme } from 'next-themes';
import { LngLatBoundsLike } from 'react-map-gl';
import {
  ACTIVE_JOURNEY_ID,
  ATHLETE,
  ATHLETE_STRAVA_ID,
} from '../_lib/constants';
import { TEXAS_START_DATE } from '../_lib/live-event';

interface TrackMapProps {
  children?: React.ReactNode;
  token: string;
  bounds?: LngLatBoundsLike;
  tileVersion: number;
}
export function TrackMap({
  bounds,
  token,
  tileVersion,
  children,
}: TrackMapProps) {
  const { resolvedTheme } = useTheme();
  const isLive = true;
  return (
    <BaseMap
      baseTheme={resolvedTheme === 'dark' ? 'dark' : 'light'}
      type="base"
      token={token}
      initialViewState={{
        bounds,
        fitBoundsOptions: {
          padding: 100,
        },
      }}
      onClick={e => console.log(e.lngLat)}
      styleDiffing={false}>
      <UserTilesSource stravaId={ATHLETE_STRAVA_ID} version={tileVersion}>
        <JourneyLayers journeyId={ACTIVE_JOURNEY_ID} />
        {isLive && (
          <JourneyLiveLayers journeyId={ACTIVE_JOURNEY_ID} user={ATHLETE} />
        )}
      </UserTilesSource>

      {children}
    </BaseMap>
  );
}

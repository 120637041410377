'use client';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@streetferret/ui-web/avatar';
import { cn } from '@streetferret/utils';
import { useMemo, useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { DateTime } from '@streetferret/ui-web/typography/date-time';
import { RouterOutputs } from '@streetferret/api-client/client';

export interface LivePositionMarkerProps {
  position: RouterOutputs['journey']['tracking']['livePosition'];
  user: {
    firstname: string | null;
    lastname: string | null;
    profile: string | null;
  };
}

export function LivePositionMarker({
  position,
  user,
}: LivePositionMarkerProps) {
  const [popup, setPopup] = useState(false);
  const initials = useMemo(
    () => `${user.firstname?.at(0)}${user.lastname?.at(0)}`,
    [user.firstname, user.lastname],
  );
  return (
    <>
      {position && (
        <Marker
          longitude={position.geometry.coordinates[0]}
          latitude={position.geometry.coordinates[1]}
          anchor="bottom"
          onClick={e => {
            e.originalEvent.stopPropagation();
            setPopup(true);
          }}>
          <div className="relative h-7 w-7">
            <div
              className={cn(
                'absolute inset-0 animate-ping bg-red-500 rounded-full',
              )}
              style={{ animationDuration: '2s' }}
            />
            <Avatar className="border-2 !h-7 !w-7 cursor-pointer">
              <AvatarImage src={user.profile ?? ''} alt="User" />
              <AvatarFallback className="!bg-foreground !text-background">
                {initials}
              </AvatarFallback>
            </Avatar>
          </div>
        </Marker>
      )}
      {popup && position ? (
        <Popup
          anchor="top"
          longitude={position.geometry.coordinates[0]}
          latitude={position.geometry.coordinates[1]}
          closeButton={false}
          onClose={() => setPopup(false)}>
          <div className="">
            <div className="font-bold">Last Update</div>
            <div>
              <span>{position.geometry.coordinates[0]}</span>
              <span>, </span>
              <span>{position.geometry.coordinates[1]}</span>
            </div>
            {position.properties?.timestamp && (
              <DateTime date={new Date(position.properties.timestamp)} />
            )}
          </div>
        </Popup>
      ) : null}
    </>
  );
}

export const ATHLETE_STRAVA_ID = 74067187;
export const DONATION_PAGE_URL =
  'https://runacrosstexas.betterworld.org/campaigns/matt-johnsons-run-across-texas-b';

export const ANALYTICS_SITE_ID = '29514530-64eb-4a99-9841-d305d1dba7e7';

export const ACTIVE_JOURNEY_ID = 37 as unknown as bigint;

export const ATHLETE = {
  firstname: 'Matt',
  lastname: 'Johnson',
  profile:
    'https://dgalywyr863hv.cloudfront.net/pictures/athletes/74067187/21226034/1/large.jpg',
};
